<template>
  <div>
    <shell title="当前时间">
      <div class="quittingTimeCss">
        <div class="time-box-css">
          {{time}}
        </div>
      </div>
    </shell>
  </div>
</template>

<script>
import shell from "./shell.vue";
import moment from 'moment';
let timeInterval = null;
export default {
  components: {
    shell,
  },
  data(){
    return{
      time: ''
    }
  },
  mounted(){
    timeInterval = setInterval(() => {
      this.time = moment().format("HH:mm:ss");
    }, 1000);
  },
  beforeDestroy(){
    if(timeInterval){
      clearInterval(timeInterval);
    }
  }
};
</script>

<style scoped>
.quittingTimeCss {
  width: 420px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-box-css {
  font-size: 70px;
  font-family: "crjk";
  color: #8268f5;
  font-weight: 600;
  margin-top: -20px;
  letter-spacing: 5px;
}
</style>
