<template>
  <div>
    <shell :title="'当前请假人员(' + listArr.length + '人)'">
      <div class="askForLeaveCss">
        <el-carousel height="7.9rem" :interval="4000" arrow="never">
          <el-carousel-item class="carousel-item-css" v-for="num in carouselNum" :key="num">
            <div v-for="(item, index) in listArr.slice((num - 1) * 10, num * 10)" :key="index">
              <img class="askForLeave_image_css" :src="item.photo" @error="imgError((num - 1) * 10 + index)" />
              <div class="askForLeave_userName_css">{{ item.userName }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </shell>
  </div>
</template>

<script>
import shell from "./shell.vue";
import getAva from "./photoList";
export default {
  components: {
    shell,
  },
  data() {
    return {
      carouselNum: 0, // 页数
      listArr: [
        { userName: "陈伟人", photo: "" },
        { userName: "黄逸平", photo: "" },
        { userName: "赵琼名", photo: "" },
        { userName: "陈美平", photo: "" },
        { userName: "梁家豪", photo: "" },
        { userName: "许梦恩", photo: "" },
        { userName: "袁宗翰", photo: "" },
        { userName: "施秋萍", photo: "" },
        { userName: "陈伟人", photo: "" },
        { userName: "黄逸平", photo: "" },
        { userName: "赵琼名", photo: "" },
        { userName: "陈美平", photo: "" },
        { userName: "梁家豪", photo: "" },
        { userName: "许梦恩", photo: "" },
        { userName: "袁宗翰", photo: "" },
        { userName: "施秋萍", photo: "" },
        { userName: "陈伟人", photo: "" },
        { userName: "黄逸平", photo: "" },
        { userName: "赵琼名", photo: "" },
        { userName: "陈美平", photo: "" },
        { userName: "梁家豪", photo: "" },
        { userName: "许梦恩", photo: "" },
        { userName: "袁宗翰", photo: "" },
        { userName: "施秋萍", photo: "" },
        { userName: "陈伟人", photo: "" },
        { userName: "黄逸平", photo: "" },
        { userName: "赵琼名", photo: "" },
        { userName: "陈美平", photo: "" },
        { userName: "梁家豪", photo: "" },
        { userName: "许梦恩", photo: "" },
        { userName: "袁宗翰", photo: "" },
        { userName: "施秋萍", photo: "" },
      ],
    };
  },
  created() {
    this.carouselNum = Math.ceil(this.listArr.length / 10);
  },
  methods: {
    imgError(idx) {
      this.listArr[idx].photo = getAva();
    },
  },
};
</script>

<style scoped>
/deep/ .el-carousel__button {
  width: 10px;
}
/deep/ .el-carousel__indicators--horizontal {
  bottom: 0;
  left: 0 !important;
  right: 0 !important;
  transform: none;
  text-align: center;
}
.askForLeaveCss {
  width: 420px;
  height: 155px;
}
.askForLeave_image_css {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
}
.askForLeave_userName_css {
  font-size: 14px;
  text-align: center;
}
.carousel-item-css {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.carousel-item-css > div {
  width: 80px;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
