<template>
    <div class="middle-box-css">
      <img src="@/assets/largeScreen/yuanquan.png">
      <div class="middle-content-css">
        <img src="@/assets/largeScreen/yuanqiu.png">
        <div class="de-css">德 <span>30256</span></div>
        <div class="zhi-css">智 <span>39256</span></div>
        <div class="ti-css">体 <span>21836</span></div>
        <div class="mei-css">美 <span>20346</span></div>
        <div class="lao-css">劳 <span>18526</span></div>
      </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        }
    }
</script>

<style scoped>
.middle-box-css{
  width: 1030px;
  height: 550px;
}
.middle-box-css>img{
  position: absolute;
  top: 0;
  left: 0;
  width: 1030px;
}
.middle-content-css{
  position: absolute;
  top: 0;
  left: 0;
  width: 1030px;
  animation: upKey 4s infinite;
}
@keyframes upKey {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.middle-content-css>img{
  position: absolute;
  top: 0;
  left: 0;
  width: 1030px;
}
.middle-content-css>div{
  transform: translateZ(0);
  font-family: "crjk";
  color: #5e76fd;
  font-size: 30px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
}
.middle-content-css>div>span{
  margin-top: -2px;
  font-size: 31px;
  display: block;
  letter-spacing: 3.5px;
  margin-left: 5px;
}
.de-css{
  position: absolute;
  top: 56px;
  left: 20px;
  color: #fff !important;
}
.de-css span{
    margin-top: 0px !important;
}
.zhi-css{
  position: absolute;
  top: 35px;
  left: 855px;
  color: #fff !important;
}
.ti-css{
  position: absolute;
  top: 313px;
  left: 54px;
}
.mei-css{
  position: absolute;
  top: 308.5px;
  left:716px;
}
.lao-css{
  position: absolute;
  top: 424px;
  left: 392px;
  color: #fff !important;
}
</style>