<template>
  <div class="ps_pages_Css">
    <div class="title-garde-css">
      二年级德智体美劳实时监控
    </div>
    <!-- 主图 -->
    <middle class="middle-box-css" />
    <!-- 班级得分 -->
    <classRanking class="classRanking-box-css" />
    <!-- 班级学生排名 -->
    <classStudent class="classStudent-box-css" />
    <!-- 当前请假人员 -->
    <askForLeave class="askForLeave-box-css" />
    <!-- 放学时间 -->
    <quittingTime class="quittingTime-box-css" />
    <!-- 教师排名 -->
    <teacherRanking class="teacherRanking-box-css" />
    <!-- 通知公告 -->
    <notification class="notification-box-css" />
    
  </div>
</template>

<script>
import middle from "./components/middle.vue";
import classRanking from "./components/classRanking.vue";
import classStudent from "./components/classStudent.vue";
import quittingTime from "./components/quittingTime.vue";
import askForLeave from "./components/askForLeave.vue";
import teacherRanking from "./components/teacherRanking.vue";
import notification from "./components/notification.vue";
export default {
  components: {
    middle,
    classRanking,
    classStudent,
    quittingTime,
    askForLeave,
    teacherRanking,
    notification
  },
  data() {
    return {};
  },
};
</script>
<style>
@font-face {
  font-family: "crjk";
  src: url(../../assets/largeScreen/54115de07a5907a9b1574992473.ttf);
}
@font-face {
  font-family: "pmzdb";
  src: url(../../assets/largeScreen/5c8a071de32c71552549661.ttf);
}
@font-face {
  font-family: "wdzjyhx";
  src: url(../../assets/largeScreen/81425e6332500e3301583559248.ttf);
}
</style>
<style scoped>
.ps_pages_Css {
  /* background-image: url("../../assets/largeScreen/5d203aeceeb331562393324642.jpg");
  background-size: cover; */
  background-color: #ebeefd;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.title-garde-css{
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  font-family: "crjk";
  color: #8a70ff;
  font-size: 44px;
  font-weight: 600;
}
.middle-box-css{
  position: absolute;
  top: 138px;
  left: 50%;
  transform: translateX(-50%);
}
.classRanking-box-css{
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.classStudent-box-css{
  position: absolute;
  top: 20px;
  left: 20px;
}
.quittingTime-box-css {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.askForLeave-box-css {
  position: absolute;
  bottom: 200px;
  right: 20px;
}
.teacherRanking-box-css{
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.notification-box-css{
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
